import {UserDataInfo} from '@/plugins/api'
const userData = new UserDataInfo();
export default class AgendaModel {
  constructor(item) {
    if (item) {
      this.name = item.name ? item.name : null;
      this.description = item.description ? item.description : null;
      this.color = item.color ? item.color : null;
      this.owner_id = userData.ownerId();
      this.service_id = item.service_id ? item.service_id : [];
      this.account = item.account ? item.account : null;
      this.way = item.way ? item.way : 'one way';
      this.activation = item.activation ? item.activation : false
    }
  }
}
