<template>
  <div class="p-grid">
    <div class="p-col-12">
      <Breadcrumb
        :home="breadcrumbHome"
        :model="breadcrumbItems"
        class="p-mb-4"
      />
    </div>
    <div class="p-col-6">
      <h3 class="font-semibold">
        {{ i18n.$t('View Agenda') }} : {{ agenda.name }}
      </h3>

      <div class="form-input p-mt-3">
        <label>Services : </label>
        <div v-for="serv in agenda.services" :key="serv._id">
          {{ serv.name }}
        </div>
      </div>

      <div class="form-input p-mt-3">
        <label>Account : </label>
        {{ agenda.account }}
        <span
          v-if="agenda.synchronisation == 'Google'"
          class="pi pi-google"
          style="fontsize: 2rem"
        ></span>
        <span
          v-if="agenda.synchronisation == 'Apple'"
          class="pi pi-apple"
          style="fontsize: 2rem"
        ></span>
        <span
          v-if="agenda.synchronisation == 'Outlook'"
          class="pi pi-microsoft"
          style="fontsize: 2rem"
        ></span>
      </div>

      <div class="form-input p-mt-3">
        <label>Synchronisation : </label>
        {{ agenda.synchronisation }}
      </div>

      <div class="form-input p-mt-3">
        <label>Description : </label>
        {{ agenda.description }}
      </div>
      <div class="form-input p-mt-3">
        <label>Color : </label>
        <ColorPicker v-model="agenda.color" />
      </div>

      <div class="form-input p-mt-4">
        <Button
          label="Edit"
          class="p-button-raised p-button-text p-button-danger"
          @click="
            $router.push({
              path: '/agenda/edit',
              query: { agenda: agenda._id }
            })
          "
        />
        <Button
          @click="$router.push({ path: '/agenda' })"
          label="Plain"
          class="p-button-raised p-button-text p-button-plain"
        >
          <span>{{ i18n.$t('Back') }}</span>
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/plugins/axios';
import { useRoute } from 'vue-router';
import { reactive } from 'vue';
import useI18n from '@/plugins/useI18n';
import AgendaModel from '@/models/agenda';

export default {
  data: () => ({
    breadcrumbHome: { icon: 'pi pi-home', to: '/' },
    breadcrumbItems: [{ label: 'Agenda list', to: '/agenda' }]
  }),
  setup() {
    const route = useRoute();
    const { i18n } = useI18n();

    const agenda = reactive(new AgendaModel({}));
    axios.get(`agenda/${route.query.agenda}`).then((response) => {
      const data = response.data.data.items[0];
      for (const key in data) {
        agenda[key] = data[key];
      }
      agenda.services.forEach((element) => {
        agenda.service_id = [element._id];
      });
    });

    return {
      agenda,
      i18n
    };
  }
};
</script>