<template>
  <div class="p-grid">
    <div class="p-col-12">
      <Breadcrumb
        :home="breadcrumbHome"
        :model="breadcrumbItems"
        class="p-mt-4 p-mb-4"
      />
      <div class="card">
        <h4>{{ i18n.$t('Category Services') }}</h4>
        <div class="p-mb-4">
          <Button
            class="bg-teal-500 text-white rounded p-2"
            @click="$router.push({ path: '/category/add' })"
            v-if="can('v1.category.index')"
          >
            {{ i18n.$t('Add') }} {{ i18n.$t('Category') }}
          </Button>
        </div>
        <DataTable
          :value="categories"
          dataKey="_id"
          :rowHover="true"
          class="apin-data-table"
          v-model:selection="selected"
        >
          <template #header>
            <div class="table-header">{{ i18n.$t('List of') }} Category</div>
          </template>
          <Column selectionMode="multiple" headerStyle="width: 3em"></Column>
          <Column field="name" header="Name"></Column>
          <Column field="color" header="Color">
            <template #body="slotProps">
              <ColorPicker v-model="slotProps.data.color" />
            </template>
          </Column>
          <Column :exportable="false">
            <template #body="slotProps">
              <ButtonConfirm @accept="onDelete(slotProps.data.id)" v-if="can('v1.category.delete')">
                <Button
                  icon="pi pi-trash"
                  class="p-button-rounded p-button-danger"
                />
              </ButtonConfirm>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import {
  FETCH_CATEGORY_SERVICE,
  GET_CATEGORY_SERVICE,
  GET_USERDATA
} from '@/store/types';
import { API } from '@/plugins/api';
import useI18n from '@/plugins/useI18n';
import ColorPicker from 'primevue/colorpicker';
export default {
  data: () => ({
    breadcrumbHome: { icon: 'pi pi-home', to: '/profile/myprofile' },
    breadcrumbItems: [{ label: 'Agenda', to: '/agenda' }]
  }),
  components: {
    ColorPicker
  },
  setup() {
    const { i18n } = useI18n();
    const store = useStore();
    const categories = computed(() => store.getters[GET_CATEGORY_SERVICE]);
    const userData = computed(() => store.getters[GET_USERDATA]);
    const selected = ref([]);
    const fields = ref([]);
    const api = new API();

    store.dispatch(FETCH_CATEGORY_SERVICE, userData.value.organisation_id);

    async function onDelete(id) {
      api.delete('category', id, FETCH_CATEGORY_SERVICE);
    }


    return { categories, i18n, fields, selected, onDelete };
  }
};
</script>
