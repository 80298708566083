import {UserDataInfo} from '@/plugins/api'
const userData = new UserDataInfo();
export default class CategoryModel {
  constructor(item) {
    if (item) {
      this.organisation_id = userData.organisationId();
      this.name = item.name ? item.name : null;
      this.description = item.description ? item.description : null;
      this._type = item._type ? item._type : "Service";
      this.color = item.color ? item.color : null;
      this.icon = item.icon ? item.icon : null;
      this.parent_id = item.parent_id ? item.parent_id : null;
    }
  }
}
