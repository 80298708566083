<template>
  <div class="p-grid">
    <div class="p-col-12">
      <Breadcrumb
        :home="breadcrumbHome"
        :model="breadcrumbItems"
        class="p-mt-4 p-mb-4"
      />
      <div class="card">
        <p class="text-gray-800 text-2xl">
          {{ i18n.$t('Add') }} {{ i18n.$t('Agenda') }}
        </p>
        <form
          class="agenda-form mt-4"
          ref="agendaForm"
          @submit.prevent="onSave"
        >
          <div class="form-input">
            <input type="hidden" name="_type" v-model="agenda._type" />
          </div>
          <div class="form-input">
            <p class="p-mt-2">{{ i18n.$t('Title') }}</p>
            <InputText
              type="text"
              name="name"
              class="border border-teal-500 px-2 py-1 rounded"
              v-model="agenda.name"
              required
            />
          </div>

          <div class="form-input p-mt-3">
            <p class="">{{ i18n.$t('Select Service') }}</p>
            <MultiSelect
              name="service"
              v-model="agenda.service_id"
              :options="services"
              optionLabel="name"
              optionValue="_id"
              required
            />
          </div>
          <div class="form-input p-mt-3">
            <p class="p-mt-2">{{ i18n.$t('Way') }}</p>
            <div class="radio-input p-mt-2">
              <RadioButton
                id="oneway"
                name="_type"
                value="one way"
                v-model="agenda.way"
              />
              <label for="oneway">{{ i18n.$t('One way') }}:</label>
            </div>
            <div class="radio-input p-mt-2">
              <RadioButton
                id="twoway"
                name="_type"
                value="two way"
                v-model="agenda.way"
              />
              <label for="twoway">{{ i18n.$t('Two way') }}</label>
            </div>
          </div>
          <div class="form-input p-mt-3">
            <p class="p-mt-2">{{ i18n.$t('Activation') }}:</p>
            <div class="radio-input p-mt-2">
              <RadioButton
                id="no"
                name="_type"
                :value="false"
                v-model="agenda.activation"
              />
              <label for="no">{{ i18n.$t('No') }}</label>
            </div>
            <div class="radio-input p-mt-2">
              <RadioButton
                id="yes"
                name="_type"
                :value="true"
                v-model="agenda.activation"
              />
              <label for="yes">{{ i18n.$t('Yes') }}</label>
            </div>
          </div>

          <div class="form-input p-mt-3">
            <p class="p-mt-2">{{ i18n.$t('Synchronisation') }}</p>
            <Dropdown
              id="synchronisation"
              :options="synchronisat"
              v-model="sync"
              optionLabel="name"
              class="border border-gray-500 focus:border-main rounded p-1"
              style="width: 100%"
            ></Dropdown>
          </div>
          <div class="form-input p-mt-3">
            <label>{{ i18n.$t('Account') }}</label>
            <InputText
              type="text"
              name="account"
              class="border border-teal-500 px-2 py-1 rounded"
              v-model="agenda.account"
              required
            />
          </div>

          <div v-show="isOption">
            <Button
              @click="options(false)"
              label="hide options"
              class="p-button-link"
            />
            <div class="form-input p-mt-3">
              <label>{{ i18n.$t('Description') }}</label>
              <Textarea
                name="description"
                class="border border-teal-500 px-2 py-1 rounded"
                v-model="agenda.description"
              />
            </div>

            <div class="form-input p-mt-3">
              <label>{{ i18n.$t('Color') }}</label>
              <ColorPicker v-model="agenda.color" />
            </div>
          </div>

          <div v-show="!isOption">
            <Button
              @click="options(true)"
              label="display options"
              class="p-button-link"
            />
          </div>

          <div class="form-input p-mt-4">
            <Button type="submit" class="loading-btn">
              <spinner v-if="isLoading" color="white" />
              <span v-if="!isLoading">{{ i18n.$t('Save') }}</span>
            </Button>
          </div>
          <div class="form-input p-mt-4">
            <Button
              @click="$router.push({ path: '/agenda' })"
              label="Plain"
              class="p-button-raised p-button-text p-button-plain"
            >
              <span>{{ i18n.$t('Cancel') }}</span>
            </Button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, computed } from 'vue';
import { useRouter } from 'vue-router';
import Spinner from '@/components/UI/Spinner.vue';
import axios from '@/plugins/axios';
import { useStore } from 'vuex';
import useI18n from '@/plugins/useI18n';
import ColorPicker from 'primevue/colorpicker';
import {
  FETCH_ALL_SERVICES,
  GET_SERVICES,
} from '@/store/types';
import AgendaModel from '@/models/agenda';
export default {
  data: () => ({
    breadcrumbHome: { icon: 'pi pi-home', to: '/profile/myprofile' },
    breadcrumbItems: [{ label: 'Agenda', to: '/agenda' }],
    isOption: false
  }),
  components: {
    Spinner,
    ColorPicker
  },
  methods: {
    keelItWithFire() {
      document
        .getElementsByClassName('.p-button')[1]
        .setAttribute('ref', 'uploadImg');
    }
  },
  setup() {
    const { i18n } = useI18n();
    const store = useStore();
    const router = useRouter();
    const isLoading = ref(false);
    const isOption = ref(false);
    const agendaForm = ref();
    const isError = ref(false);

    const synchronisat = [
      {
        name: 'Google'
      },
      {
        name: 'OutLook'
      },
      {
        name: 'Apple'
      }
    ];

    const services = computed(() => store.getters[GET_SERVICES]);

    store.dispatch(FETCH_ALL_SERVICES);

    const sync = ref('');

    const agenda = reactive(new AgendaModel({}));

    async function options(visibility) {
      isOption.value = visibility;
    }

    async function onSave() {
      const formdata = new FormData(agendaForm.value);
      Object.assign(agenda, { synchronisation: sync.value.name });
      try {
        if (agenda.color === null) {
          agenda.color = 'ff0a0a';
        }
        await axios.post(`agenda`, agenda);
        isError.value = false;
        router.push({ path: `/agenda` });
      } catch (error) {
        isError.value = true;
      }
    }

    return {
      i18n,
      agenda,
      onSave,
      synchronisat,
      sync,
      isLoading,
      isOption,
      services,
      options
    };
  }
};
</script>

<style scoped>
.form-input {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 420px;
  position: relative;
}
.loading-btn {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.radio-input label {
  margin-left: 10px;
}
.file-wrapper {
  position: relative;
  text-align: center;
  display: table-cell;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  cursor: pointer;
}
.file-wrapper input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
}
.tui-datepicker-input {
  width: 100%;
  font-size: 1rem;
  color: #495057;
  background: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 2px;
  height: 32px;
}
</style>
