<template>
  <div class="p-grid p-fluid">
    <Breadcrumb
      :home="breadcrumbHome"
      :model="breadcrumbItems"
      class="p-mt-4 p-mb-4"
    />
    <div class="p-col-12">
      <div class="card">
        <h3 class="font-bold text-3xl text-left">
          {{ i18n.$t('Company categories') }} : {{ agency.name }}
        </h3>
        <Tree
          :value="categoryTreeData"
          selectionMode="checkbox"
          v-model:selectionKeys="selectedTreeValueRef"
        ></Tree>
        <div v-if="showResult" class="mt-4">
          <p v-if="isError" class="font-semibold text-red-600">
            {{ i18n.$t('Oops, something went wrong!') }}
          </p>
          <p v-if="!isError" class="font-semibold text-green-600">
            {{ i18n.$t('Saved successfully') }}
          </p>
        </div>
        <Button
          class="bg-teal-500 font-semibold text-white loading-btn rounded p-mt-4"
          @click="onSave"
        >
          <spinner v-if="isLoading" color="white" />
          <span v-if="!isLoading">{{ i18n.$t('Save') }}</span>
        </Button>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import {
  FETCH_AGENCY,
  GET_AGENCY_MODEL,
  FETCH_ALL_CATEGORIES,
  GET_CATEGORY_TREE,
  GET_SELECTED_CARTEGORY_TREE_VALUE,
  GET_USERDATA
} from '../../store/types';
import Spinner from '../../components/UI/Spinner.vue';
import useI18n from '@/plugins/useI18n';
import { API } from '@/plugins/api';

export default {
  data: () => ({
    breadcrumbHome: { icon: 'pi pi-home', to: '/' },
    breadcrumbItems: [{ label: 'my agency', to: '/agency' }]
  }),
  components: {
    Spinner
  },
  setup() {
    const store = useStore();
    const { i18n } = useI18n();
    const agency = computed(() => store.getters[GET_AGENCY_MODEL]);
    const categoryTreeData = computed(() => store.getters[GET_CATEGORY_TREE]);
    const selectedTreeValue = computed(
      () => store.getters[GET_SELECTED_CARTEGORY_TREE_VALUE]
    );
    const selectedTreeValueRef = ref();
    const showResult = ref(false);
    const isError = ref(false);
    const isLoading = ref(false);

    initData();

    watch(
      () => [selectedTreeValue.value],
      () => {
        selectedTreeValueRef.value = { ...selectedTreeValue.value };
      }
    );

    async function initData() {
      await store.dispatch(FETCH_ALL_CATEGORIES);
      await store.dispatch(FETCH_AGENCY);
    }
    const userData = computed(() => store.getters[GET_USERDATA]);
    const api = new API();
    async function onSave() {
      const selectedCategories = [];
      for (const key in selectedTreeValueRef.value) {
        if (selectedTreeValueRef.value[key].checked) {
          selectedCategories.push(key);
        }
      }

      const id = userData.value.organisation_id;
      const routePath = { path: '/agency' };
      agency.value.category = selectedCategories;
      api.update(
        'organisation/category',
        'Category',
        id,
        selectedCategories,
        routePath
      );
    }

    return {
      i18n,
      categoryTreeData,
      agency,
      selectedTreeValueRef,
      showResult,
      isError,
      isLoading,
      onSave
    };
  }
};
</script>

<style lang="scss" scoped>
.loading-btn {
  width: 120px;
  height: 40px;
}
span {
  width: 100%;
}
</style>
