<template>
  <div class="p-grid">
    <div class="p-col-12">
      <Breadcrumb
        :home="breadcrumbHome"
        :model="breadcrumbItems"
        class="p-mt-4 p-mb-4"
      />
      <div class="card">
        <p class="font-semibold text-gray-800 text-2xl">
          {{ i18n.$t('Edit') }} {{ i18n.$t('Agenda') }}
        </p>
        <form class="agenda-form mt-4" @submit.prevent="onSave">
          <div class="form-input">
            <label>{{ i18n.$t('Name') }}</label>
            <InputText
              type="text"
              name="name"
              class="border border-teal-500 px-2 py-1 rounded"
              v-model="agenda.name"
              required
            />
          </div>

          <div class="form-input p-mt-2">
            <p class="p-mt-2">{{ i18n.$t('Select Service') }}</p>

            <MultiSelect
              name="service"
              v-model="agenda.service_id"
              :options="services"
              optionLabel="name"
              optionValue="_id"
              v-if="agenda.service_id"
              required
            />
          </div>
          <div class="form-input p-mt-3">
            <p class="p-mt-2">{{ i18n.$t('Way') }}</p>
            <div class="radio-input p-mt-2">
              <RadioButton
                id="oneway"
                name="_type"
                value="one way"
                v-model="agenda.way"
              />
              <label for="oneway">{{ i18n.$t('One way') }}</label>
            </div>
            <div class="radio-input p-mt-2">
              <RadioButton
                id="twoway"
                name="_type"
                value="two way"
                v-model="agenda.way"
              />
              <label for="twoway">{{ i18n.$t('Two way') }}</label>
            </div>
          </div>
          <div class="form-input p-mt-3">
            <p class="p-mt-2">{{ i18n.$t('Activation') }}:</p>
            <div class="radio-input p-mt-2">
              <RadioButton
                id="no"
                name="_type"
                :value="false"
                v-model="agenda.activation"
              />
              <label for="no">{{ i18n.$t('No') }}</label>
            </div>
            <div class="radio-input p-mt-2">
              <RadioButton
                id="yes"
                name="_type"
                :value="true"
                v-model="agenda.activation"
              />
              <label for="yes">{{ i18n.$t('Yes') }}</label>
            </div>
          </div>

          <div class="form-input p-mt-3">
            <label>{{ i18n.$t('Description') }}</label>
            <Textarea
              name="description"
              class="border border-teal-500 px-2 py-1 rounded"
              v-model="agenda.description"
            />
          </div>
          <div class="form-input p-mt-3">
            <label>{{ i18n.$t('Color') }}</label>
            <ColorPicker v-model="agenda.color" />
          </div>
          <div class="form-input p-mt-3">
            <p class="p-mt-2">{{ i18n.$t('Synchronisation') }}</p>
            <Dropdown
              id="synchronisation"
              :options="synchronisat"
              v-model="agenda.synchronisation"
              optionLabel="name"
              optionValue="name"
              class="border border-gray-500 focus:border-main rounded p-1"
              style="width: 100%"
            ></Dropdown>
          </div>
          <div class="form-input p-mt-3">
            <label>{{ i18n.$t('Account') }}</label>
            <InputText
              type="text"
              name="account"
              class="border border-teal-500 px-2 py-1 rounded"
              v-model="agenda.account"
              required
            />
          </div>
          <div class="form-input p-mt-4">
            <Button type="submit" class="font-semibold loading-btn">
              <span>{{ i18n.$t('Save') }}</span>
            </Button>
          </div>
          <div class="form-input p-mt-4">
            <Button
              @click="$router.push({ path: '/agenda' })"
              label="Plain"
              class="p-button-raised p-button-text p-button-plain"
            >
              <span>{{ i18n.$t('Cancel') }}</span>
            </Button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/plugins/axios';
import useI18n from '@/plugins/useI18n';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { reactive, computed } from 'vue';
import { FETCH_ALL_SERVICES, GET_SERVICES } from '@/store/types';
import AgendaModel from '@/models/agenda';
import { API } from '@/plugins/api';
export default {
  data: () => ({
    breadcrumbHome: { icon: 'pi pi-home', to: '/' },
    breadcrumbItems: [{ label: 'Agenda list', to: '/agenda' }]
  }),
  setup() {
    const route = useRoute();
    const { i18n } = useI18n();
    const store = useStore();
    const services = computed(() => store.getters[GET_SERVICES]);
    
    store.dispatch(FETCH_ALL_SERVICES);

    const synchronisat = [
      {
        name: 'Google'
      },
      {
        name: 'OutLook'
      },
      {
        name: 'Apple'
      }
    ];
    const agenda = reactive(new AgendaModel({}));

    axios.get(`agenda/${route.query.agenda}`).then((response) => {
      const data = response.data.data.items[0];
      for (const key in data) {
        agenda[key] = data[key];
      }
      agenda.services.forEach((element) => {
        agenda.service_id = [element._id];
      });
    });

    const api = new API();
    
    async function onSave() {
      const id = agenda._id;
      const route = { path: `/agenda` };
      api.update('agenda', 'Agenda', id, agenda, route);
    }

    return {
      agenda,
      onSave,
      i18n,
      synchronisat,
      services
    };
  }
};
</script>

<style scoped>
.form-input {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 420px;
  position: relative;
}
.loading-btn {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.radio-input label {
  margin-left: 10px;
}
.file-wrapper {
  position: relative;
  text-align: center;
  display: table-cell;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  cursor: pointer;
}
.file-wrapper input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
}
.tui-datepicker-input {
  width: 100%;
  font-size: 1rem;
  color: #495057;
  background: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 2px;
  height: 32px;
}
</style>