<template>
  <div class="p-grid">
    <div class="p-col-12">
      <Breadcrumb
        :home="breadcrumbHome"
        :model="breadcrumbItems"
        class="p-mt-4 p-mb-4"
      />
      <div class="card">
        <p class="font-semibold text-gray-800 text-2xl">
          {{ i18n.$t('Add') }} {{ i18n.$t('Category') }}
        </p>
        <form class="cat-form mt-4" ref="CategoryForm" @submit.prevent="onSave">
          <div class="form-input">
            <input type="hidden" name="_type" v-model="cat._type" />
          </div>
          <div class="form-input">
            <label>{{ i18n.$t('Name') }}</label>
            <InputText
              type="text"
              name="name"
              class="border border-teal-500 px-2 py-1 rounded"
              v-model="cat.name"
              required
            />
          </div>
          <!--
          <div class="form-input p-mt-">
              <p class="font-semibold">{{ i18n.$t("Category Parent") }}</p>
              <Dropdown
                name="parent_id"
                v-model="cat.parent_id"
                :options="categories"
                optionLabel="name"
                optionValue="id"
              />

            </div>
           -->

          <div class="form-input p-mt-3">
            <label>{{ i18n.$t('Description') }}</label>
            <Editor v-model="cat.description" editorStyle="height: 150px">
            </Editor>
          </div>
          <div class="form-input p-mt-3">
            <label>{{ i18n.$t('Color') }}</label>
            <ColorPicker v-model="cat.color" />
          </div>
          <div class="form-input p-mt-4">
            <Button type="submit" class="font-semibold loading-btn">
              <spinner v-if="isLoading" color="white" />
              <span v-if="!isLoading">{{ i18n.$t('Save') }}</span>
            </Button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, computed } from 'vue';
import { useRouter } from 'vue-router';
import Spinner from '@/components/UI/Spinner.vue';
import axios from '@/plugins/axios';
import { useStore } from 'vuex';
import CategoryModel from '@/models/category';
import useI18n from '@/plugins/useI18n';
import ColorPicker from 'primevue/colorpicker';
import {
  FETCH_CATEGORY_SERVICE,
  GET_CATEGORY_SERVICE,
  GET_USERDATA
} from '@/store/types';

export default {
  data: () => ({
    breadcrumbHome: { icon: 'pi pi-home', to: '/profile/myprofile' },
    breadcrumbItems: [{ label: 'Category', to: '/category' }]
  }),
  components: {
    Spinner,
    ColorPicker
  },
  methods: {
    keelItWithFire() {
      document
        .getElementsByClassName('.p-button')[1]
        .setAttribute('ref', 'uploadImg');
    }
  },
  setup() {
    const { i18n } = useI18n();
    const store = useStore();
    const router = useRouter();
    const userData = computed(() => store.getters[GET_USERDATA]);

    store.dispatch(FETCH_CATEGORY_SERVICE, userData.value.organisation_id);
    const categories = computed(() => store.getters[GET_CATEGORY_SERVICE]);

    const CategoryForm = ref();
    const isError = ref(false);

    const cat = reactive(new CategoryModel({}));

    async function onSave() {
      const formdata = new FormData(CategoryForm.value);

      try {
        if (cat.color === null) {
          cat.color = 'ff0a0a';
        }
        await axios.post(`category`, cat);
        isError.value = false;
        router.push({ path: `/category` });
      } catch (error) {
        console.log('[error]', error);
        isError.value = true;
      }
    }

    return {
      i18n,
      cat,
      onSave,
      categories
    };
  }
};
</script>

<style scoped>
.form-input {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 420px;
  position: relative;
}
.loading-btn {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.radio-input label {
  margin-left: 10px;
}
.file-wrapper {
  position: relative;
  text-align: center;
  display: table-cell;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  cursor: pointer;
}
.file-wrapper input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
}
.tui-datepicker-input {
  width: 100%;
  font-size: 1rem;
  color: #495057;
  background: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 2px;
  height: 32px;
}
</style>
