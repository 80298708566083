<template>
  <div class="p-grid">
    <div class="p-col-12">
      <Breadcrumb
        :home="breadcrumbHome"
        :model="breadcrumbItems"
        class="p-mt-4 p-mb-4"
      />
      <div class="card">
        <h4>{{ i18n.$t('My') }} {{ i18n.$t('My externals agenda') }}</h4>
        <div class="p-mb-4" v-if="can('v1.agenda.create')">
          <Button
            class="bg-teal-500 text-white rounded p-2"
            @click="$router.push({ path: `/agenda/add` })"
          >
            {{ i18n.$t('Add') }} {{ i18n.$t('Agenda') }}
          </Button>
        </div>
        <DataTable
          :value="agendas"
          dataKey="_id"
          :rowHover="true"
          class="apin-data-table"
          v-model:selection="selected"
        >
          <Column selectionMode="multiple" headerStyle="width: 3em"></Column>
          <Column field="name" header="Name"></Column>
          <Column field="services" header="Service">
            <template #body="slotProps">
              <div v-for="serv in slotProps.data.services" :key="serv._id">
                {{ serv.name }}
              </div>
            </template>
          </Column>
          <Column field="color" header="Color">
            <template #body="slotProps">
              <ColorPicker v-model="slotProps.data.color" />
            </template>
          </Column>
          <Column :exportable="false">
            <template #body="slotProps">
              <Button
                icon="pi pi-bell"
                class="p-button-rounded p-button-warning p-mr-1"
                v-if="can('v1.agenda.view')"
                @click="
                  $router.push({
                    path: '/agenda/view',
                    query: { agenda: slotProps.data._id }
                  })
                "
              />
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success p-mr-2"
                v-if="can('v1.agenda.update')"
                @click="
                  $router.push({
                    path: '/agenda/edit',
                    query: { agenda: slotProps.data._id }
                  })
                "
              />
              <ButtonConfirm @accept="onDelete(slotProps.data._id)" v-if="can('v1.agenda.delete')">
                <Button
                  icon="pi pi-trash"
                  class="p-button-rounded p-button-danger"
                />
              </ButtonConfirm>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
// import { SET_UI_NOTIFICATION } from '@/store/types';
import { FETCH_AGENDA, GET_AGENDA, GET_USERDATA } from '@/store/types';
import useI18n from '@/plugins/useI18n';
import ColorPicker from 'primevue/colorpicker';
import { API } from '@/plugins/api';

export default {
  data: () => ({
    breadcrumbHome: { icon: 'pi pi-home', to: '/profile/myprofile' },
    breadcrumbItems: [{ label: 'Agenda', to: '/agenda' }]
  }),
  components: {
    ColorPicker
  },
  setup() {
    const { i18n } = useI18n();
    const store = useStore();
    const agendas = computed(() => store.getters[GET_AGENDA]);
    const userData = computed(() => store.getters[GET_USERDATA]);
    const api = new API();
    const selected = ref([]);
    const fields = ref([]);
    store.dispatch(FETCH_AGENDA, userData.value.id);

    async function onDelete(id) {
      api.delete('agenda', id, FETCH_AGENDA);
    }

    return { agendas, i18n, fields, selected, onDelete };
  }
};
</script>